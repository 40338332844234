import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Alert from '@material-ui/lab/Alert';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export enum ALERT_STATUS {
  IDLE = 'idle',
  SUCCESS='success',
  ERROR='error'
}
// Customizable Area End

import FilecompressionController, {
  Props,
  configJSON,
} from "./FilecompressionController.web";

export default class Filecompression extends FilecompressionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
inputRef = React.createRef<HTMLInputElement>();
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box>
            <input
              data-test-id="upload-photo"
              name="upload-photo"
              accept="image/*"
              ref={this.inputRef}
              style={webStyle.uploadInputStyle}
              onChange={(event)=>this.handleFileUpload(event, this.inputRef)}
              type="file"
              />
              <br/>
          <div>  
          <button data-test-id="cancelButton"  style={webStyle.actionButtonStyle} onClick={()=>this.clearSelectedFiles(this.inputRef)}>Cancel</button> 
          <button  data-test-id="compressButton" style={webStyle.actionButtonStyle} onClick={this.compressUploadedFile}>Compress</button>
                  <button  data-test-id="downloadButton" style={webStyle.actionButtonStyle} onClick={this.downloadUploadedFiles}>Download</button>
          
          </div>
          {this.state.downloadImages && 
          <div>
            <img src={this.state.compressedFiles?.toString()} width={300} height={200} alt={`Uploaded`} />
          </div>
        }
          </Box>
{
          this.state.isCompressedFile !== ALERT_STATUS.IDLE &&
          (this.state.isCompressedFile === ALERT_STATUS.SUCCESS  ? 
          <Alert data-test-id="file-compressed-success" severity="success" onClose={this.closeCompressAlert}>File Compressed Successfully</Alert>
          : <Alert data-test-id="file-compressed-error" severity="error" onClose={this.closeCompressAlert}>Error while compressing upload file</Alert>)
        } 
          {
            this.state.isFileDownloaded !== ALERT_STATUS.IDLE &&
              (this.state.isFileDownloaded === ALERT_STATUS.SUCCESS ?
                <Alert data-test-id="file-download-success" severity="success" onClose={this.closeDownloadAlert}>
                  File Downloaded Successfully
                </Alert> :
                <Alert data-test-id="file-compressed-error" severity="error" onClose={this.closeDownloadAlert}>
                  Please compress the file before download.
                </Alert>) 
          }     
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
uploadInputStyle: {
    width: "100%",
    padding: "10px",
    fontSize:20,
    marginTop:"100px",
    marginBottom: "20px",
    border: "1px solid rgba(0, 0, 0, 0.6)",
  },
  actionButtonStyle: {
    width: "150px",
    height: "45px",
    margin: "10px",
    border: "none",
    color: "#fff",
    backgroundColor: "rgb(98, 0, 238)",
    cursor: "pointer",
  },
};
// Customizable Area End
